
div.news{
	 background: 
	    linear-gradient(
	      rgba(82, 96, 110, 0.65),
	      rgba(82, 96, 110, 0.65)
	    ),
	url(../../images/news/DSC_0003.JPG)
	no-repeat;
	background-size: cover; //100%
	// background-position: 0px -150px;

	@media #{$small-up} {
		background-position: 0px 0px;
	}

	@media #{$medium-up} {
		background-position: 0px -100px;
	}
	@media #{$large-up} {
		background-position: 0px -300px;
	}

	height: 340px;
	padding-top: 60px;
}

body.news div.latest-news{
	//background-image: url(../../images/shared/clouds.png);
	margin-top: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
	background-position-x: -1px;
	h4{
		// text-align: left !important;
		//color: #777;
		color: $learning-ltblue;
	}
	
	span.news-tag{
		padding-left: 20px;
		img{
			padding-left: 8px;
			height: 25px;

		}
	}
	@media #{$small-only} {
		span.news-tag{
		padding-left: 8px;
		img{
			padding-left: 8px;
			height: 25px;

			}
		}
	}
	span.post-date{
		color: $learning-ltgrey;
	}


}

img.newsImage{
		margin-bottom: 10px !important;
	}