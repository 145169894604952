//colors
$learning-purple:rgb(189,87,181);
$learning-green:rgb(80,181,94);
$learning-orange:rgb(255,147,0);
$learning-dkblue:rgb(3,107,153);

$learning-offwht:rgb(242,247,248);

$learning-ltgrey:rgb(160,160,160);
$learning-dkgrey:rgb(102,102,102);

$learning-ltblue:rgb(55,136,193);




$oyc-blue:rgb(32,48,79);
$oyc-blue2:rgb(9,89,118);
$oyc-blue3:rgb(21,141,184);

$icandigit-blue:rgb(139,196,198);
$icandigit-orange:rgb(239,120,14);
$icandigit-green:rgb(106,152,47);
$icandigit-purple:rgb(146,105,179);
$icandigit-red:rgb(172,61,61);
$icandigit-grey:rgb(151,151,151);
$icandigit-lt-brown:rgb(139,87,42);



//fonts
$raleway: 'Raleway', sans-serif;
$merriweather: 'Merriweather', serif;
