// nav
//to center top nav, but "name" on left puts it off center
// nav.top-bar {
// text-align:center;
// }
// section.top-bar-section {
// display: inline-block;
// }

//colors
.purple{
	color: $learning-purple;
}

.green{
	color: $learning-green;
}

.orange{
	color: $learning-orange;
}

.dkblue{
	color: $learning-dkblue;
}

.overlay{
	background-color: rgba(0,0,0,.5);
}

.block{
	display: block;
}

.transPanel{
	background-color: rgba(255,255,255,.5);
	border-radius: 10px;
	padding-top: 30px;


}


 body{
// 	background-image: url(../../images/shared/clouds.png);
background-color: rgba(190,221,234,1);
color: $learning-dkgrey;
background-image: url(../../images/shared/clouds-sm.png);
background-size: cover;
 }

nav.top-bar{
	z-index: 1; // to allow for clickable links and proper scrolling of sub-services pages
}

// centered top bar with left logo
// larger screen styles

ul.center-buttons {
	// margin-left: 200px;

}

.top-bar-section ul.center-buttons{
	// margin-left: 200px !important; //adjusted from centered nav with right icons
	margin: 0 auto;
}
@media only screen and (min-width: 40em) {

  .top-bar {
    text-align: center;
  }

  ul.center-buttons {
    display: inline-block;
    //margin-left: -200px;
    li a{
    	&:hover{
		color: $learning-ltblue;
	}
    }
  }

  .logo {
    left: 10px;
    top: 10px;
    position: absolute;
    z-index: 100;

  }
}



// some mobile resets

.logo {
 // max-width: 2rem; removed?
//  margin-top: -3px;
  margin-left: 5px;

}

.menu-icon {
  padding-top: 1.2rem;
}

.top-bar.expanded {
  background: #333333;
  height: 252px !important;
}


//end centered with left logo

// #trees-1, #excavation-1, #landscapes-1, #hardscapes-1{
// 	background: url(../../images/home/home-1-bg.jpg)
// 	// background: url(../../images/home/home-2-bg.jpg)
// 	// top / 100% /* position / size */
//     no-repeat                /* repeat */; 
// 	h1{

// 		@media #{$small-only} { 
// 			font-size: 2em;
// 			margin-top: 20px;
// 			margin-bottom: 40px;
// 		}
// 		color:#fff;
// 		text-align: center;
// 		span{
// 			font-weight: 900;
// 			text-transform: uppercase;
// 		}
// 		/* tag: */
// 		// line-height: 97px;
// 		text-shadow: 1px 1px 2px #030303;
// 		margin-top:  80px;
// 		margin-bottom: 80px;
// 		font-size: 3.75em;


// 	}
// 	height: 100vh;
// 	margin-top: -45px;

// 	.row section#section-1{
// 		margin-top:10%;
// 	}
// }
.top-bar{
	background-color: $learning-offwht;
}	
.fixed .top-bar{
	background-color: $learning-offwht;
	// border-bottom: 1px solid #aacee7;
    box-shadow: -2px 1px 1px rgba(0,0,0,.25);
}
nav.top-bar ul, .top-bar-section li:not(.has-form) a:not(.button){
	//background: #fff; //was transparent

	background-color: #f2f7f8;
    // border-bottom: 1px solid #aacee7;
    // box-shadow: -2px 1px 1px rgba(0,0,0,.05);
}

.top-bar-section{
	background-color: #fff;
	background-color: #f2f7f8;
    // border-bottom: 1px solid #aacee7;
    box-shadow: -2px 1px 1px rgba(0,0,0,.1);
    padding-top: 10px;
    @media #{$medium-up} {
    	height: 60px;
    }
}

nav.top-bar ul li a{
	text-transform: uppercase;
	font-weight: 500;

}

.top-bar-section ul li {
	a{  
		@media #{$medium-up} {
			@include single-transition(color, 300ms, ease-in-out);
			&:hover{
		color: $learning-ltblue;
	}
		}
		&:hover{
		color: $learning-ltblue;
	}
	}
	
	// a.home{
	//  &:hover{
	//  	color: $icandigit-blue;
 // 	 }
 //    }

	// a.trees{
	//  &:hover{
	//  	color: $icandigit-green;
 // 	 }
 //    }

 //    a.excavation{
	//  &:hover{
	//  	color: $icandigit-orange;
 // 	 }
 //    }

 //    a.landscapes{
	//  &:hover{
	//  	color: $icandigit-purple;
 // 	 }
 //    }

 //    a.hardscapes{
	//  &:hover{
	//  	color: $icandigit-red;
 // 	 }
 //    }


}

.top-bar-section .has-dropdown > a::after{
	border:none;
}

.top-bar-section ul li, .top-bar-section li.active:not(.has-form) a:not(.button){
	background-color: transparent;

}

.top-bar-section ul li.active a, .top-bar-section ul li.active a span, .top-bar-section li.active:not(.has-form) a:not(.button){
	color: $learning-ltblue;
	&:hover{
		color: $learning-dkgrey;
	}

}

.top-bar-section ul li a, .top-bar-section ul li a span{
	font-size: 1rem;
	 //text-shadow: 1px 1px 1px rgba(0,0,0,.5);
	 // color: #000;
	 &:hover{
		color: $learning-ltblue;
	}
	
}

.top-bar-section ul li > a{
	color: $learning-ltgrey;
	&:hover{
		color: $learning-ltblue;
	}
}

.top-bar-section ul li a.homeIcon{
	background-image: url("../../images/shared/home.png") !important;
	background-repeat: no-repeat !important;
	background-size: 20px 20px !important;
	background-position: 0px 10px !important;
	//padding-right: 2rem !important; //fixed for desktop nav

	
	
	//background-color: red;
}

.top-bar-section ul li.active a.homeIcon{
	background-image: url("../../images/shared/home-active.png") !important;
}

.expanded .top-bar-section ul li > a, .expanded .top-bar-section ul li > a span{
	color: $learning-ltgrey;
	text-shadow: none;

	&:hover{
		color: $learning-ltblue;
	}

}

.expanded{

	height: 350px !important;
	background-color: #fff !important;
	li.name{
			h1{
				a{color: $icandigit-grey;}
			}
		}
	a.homeIcon{margin-left: .9375rem};
}

li.moved ul > li.parent-link a{
	display: none !important;
}

.top-bar-section .has-dropdown.moved>.dropdown{height: 207px !important;}

.transparentNav{
	
	background: transparent;
}

.whiteNav{
	background: #fff;
	box-shadow: 0px 1px 5px $icandigit-grey;
	@media #{$medium-up} {
		@include single-transition(background, 1s, ease-in-out);
	}
	.top-bar-section ul li > a, .expanded .top-bar-section ul li > a, .expanded .top-bar-section ul li > a span{
		color: $icandigit-grey;
		text-shadow: none;

		&:hover{
		color: $learning-ltblue;
	}
		
		
		

	}
	.top-bar-section ul li a.active span, .top-bar-section ul li a span{
			text-shadow: none;
			// color: $icandigit-blue;
		}


	ul.title-area{
		li.name{
			h1{
				a{color: $icandigit-grey;}
			}
		}
		li.menu-icon{
			a{ 

				span{
					color: $icandigit-grey;
				}

				&:after{
					color: $icandigit-grey;
				}
			}
			a span::after{
				box-shadow: 0 0 0 1px $icandigit-grey, 0 7px 0 1px $icandigit-grey, 0 14px 0 1px $icandigit-grey;
			}
		}
	}
	
}

.whiteBg{
	
		background-color: #fff !important;
}


a#site-name{
	text-align: left;
}



.top-bar .toggle-topbar.menu-icon a{
font-family: $raleway;
color: $learning-ltgrey;
}

.top-bar .toggle-topbar.menu-icon a span::after{

	box-shadow: 0 0 0 1px $learning-ltgrey,0 7px 0 1px $learning-ltgrey,0 14px 0 1px $learning-ltgrey;
}

.top-bar-section .dropdown li.title h5 a{
	color: $learning-dkgrey;
}


a#site-name{
	text-transform: none;
}

.top-bar-section ul{
	margin: 0 auto;
}

//nav fix styles
.top-bar-section li:not(.has-form) a:not(.button){
	&:hover{
		color: $learning-ltblue;
	}
}

//animate
// @include keyframes(bounce) {
// 	0%, 20%, 50%, 80%, 100% {
//     @include transform(translateY(0));
//   }
// 	40% {
//     @include transform(translateY(-30px));
//   }
// 	60% {
//     @include transform(translateY(-15px));
//   }
// }


// head

div.hero{
	// background: url("../../images/shared/header.jpg") no-repeat;
	// width: 100%;
	// height: 192px;
}


//service landing pages
.service-head p.service-description{
		font-size: 2rem;
		@media only screen and (max-width: 40em) { 
			font-size: 1.5rem;
		}
		color:#fff;
		@media #{$small-only} { 
		margin-bottom: 65px;
		}
}

section.service{
	ul {
		margin-left: 0px;
	}
	ul li{
		list-style: none;
	}
	
	margin-top: 30px;

	ul li:not(:first-child){
		// border-top: 1px solid $icandigit-grey;
		margin-top: 30px;
	}
	
	h1{
		//margin-top: 30px;
		font-weight: 400;
		color:#8B572A;
		text-align: left;
	}

	h3{
		font-family: $merriweather;
		color: $icandigit-grey;
		font-size: 1.75em;

	}
	h4{
		text-transform: capitalize;
		font-size: 1.5rem;
	}


}

section.service-description{
	p{//font-size: 1rem;
	color:$icandigit-grey;
	text-align: left;
	}

	a.readMore{
		float: right;
		text-align: left;
		color: $icandigit-blue;
		&:hover{
			opacity: .75;

		}
		@include single-transition(opacity, 200ms, ease-in-out);

		
		margin-top: -15px;
	}

	a.titleLink{
		color: $icandigit-lt-brown;
		&:hover{
			opacity: .75;

		}
		@include single-transition(opacity, 200ms, ease-in-out);
	}
}


// footer
footer{
	// margin-top:20px;
	// border-top:1px solid #999;
	box-shadow: -2px -1px 1px rgba(0,0,0,.1);
	padding-top:20px;
	padding-bottom:20px;
	p{
		position: relative;
		top: 6px;
		color:$icandigit-grey !important;
		&:hover{
			color: $learning-dkblue;
			@include single-transition(color, 200ms, ease-in-out);
		}

	}
	a{
		color:$icandigit-grey;
		&:hover{
			color: $learning-dkblue;
			@include single-transition(color, 200ms, ease-in-out);

		}
	}
	background-color: rgba($learning-offwht,.75);

	.ss-social, .ss-icon{
		font-size: 1.5em;
		position: relative;
		top: 6px;
		color: $icandigit-grey;
		&:hover{
			color: $learning-dkblue;
			@include single-transition(color, 200ms, ease-in-out);

		}

	}
	p.copyright{
		color: $icandigit-grey;
	}

	a.emailUs{
		@media #{$small-only} {
			// font-size:.75em;
		}
	}
}

@media only screen and (min-width: 40.0625em)
	{
		.top-bar-section .has-dropdown>a{
		padding-right: 0.9375rem !important;
		}
		.top-bar-section .has-dropdown>a{}
	}

	.top-bar-section ul.dropdown li.active{
		background-color: $learning-ltblue;
		a{color: #fff !important;}
	}

@media only screen and (min-width: 40.0625em)
	{
		.top-bar-section .dropdown li:not(.has-form):not(.active)>a:not(.button) {
			background-color: $learning-offwht;
			

			&:hover{
				background-color: $learning-ltblue;
				color: #fff;
			}
		}

		.top-bar-section .dropdown li:not(.has-form):not(.active)>a.preschool{
			color: $learning-purple;
		}
		.top-bar-section .dropdown li:not(.has-form):not(.active)>a.pre-k{
			color: $learning-green;
		}
		.top-bar-section .dropdown li:not(.has-form):not(.active)>a.childcare{
			color: $learning-orange;
		}
		.top-bar-section .dropdown li, top-bar-section .dropdown li>a{
			&:hover{
				background-color: $learning-ltblue !important;
			}
		}
	}

.news-key-modal-i{
	font-size: .5em;
	position: relative;
	top:-15px;
}

.news-key-modal{
	ul{
		li{
			list-style: none;
			margin-top: 10px;
			
		}

	}
	img{
				border-radius: none !important;
				max-height: 30px;

			}	
			img.chrys{
				padding-left: 25px;
			}
}

//off canvas for mobile services
// aside.left-off-canvas-menu{
// 	min-height: 100%;
// 	overflow: visible;
// }

// #trees-services{
//   height: 100%;
//   .columns, .off-canvas-wrap{
//   	min-height: 100%;

//   }

// }

// tables

// .arrowFix{
// 	position:fixed;
// 	width: 100%;
// }

// .fixer{
// 	position: relative;
// }
// .arrow {
//   // position: fixed;
//   // bottom: 0;
//   // left: 50%;
//   // margin-left:-20px;
//   position: relative;
//   margin: 0 auto;
//   width: 45px;
//   height: 24px;
//   background-image: url(../../images/shared/down-arrow.svg);
//   background-size: contain;
//   // -vendor-animation-iteration-count: infinite;
//   // @include animate-prefixer(animation-duration, 1s);
// }


// --------------  sub-service pages

// ------ general
// ---breadcrumb area



 // .breadcrumbBand{
 // 	&#excavation{background-color: $icandigit-orange;}
 // 	&#trees{background-color: $icandigit-green;}
 // 	&#landscapes{background-color: $icandigit-purple;}
 // 	&#hardscapes{background-color: $icandigit-red;}


 // 	border: 1px solid #fff;
 // 	border-right: none;
 // 	border-left: none;
 // 	color:#fff;
 // 	position: relative;
 // 	top:94px;
 // 	height: auto; //40px;
 // 	padding-top: 7px;
 // 	margin-bottom: 40px;

 // 	p.crumbsy{
 // 		text-transform: capitalize;
 // 		font-family: $raleway;
 // 		font-size: 1rem;
 // 		margin-bottom: .5rem;
 // 		a{
 // 			// text-decoration: underline;
 // 			color: #fff;
 // 			text-transform: capitalize;
 // 			&:hover{
 // 				text-decoration: none;

 // 			}
 // 		}

 // 		span.activeCrumb{
 // 			// color: $icandigit-blue;
 // 			// text-shadow: 1px 1px 1px #fff;



 // 		}

 // 	}
 // }
 // div.subCatHead{
 // 	background: url(../../images/home/home-1-bg.jpg) no-repeat;
 // 	background-size: cover !important;
	// position: relative;
	// top: -91px;
	// //z-index: -1;  //now not needed because top-bar is now z-index of 1
	// width: 100%;
	
	// h1{
	// 	color: #fff;
	// 	@media #{$small-only} { 
	// 	font-size: 1.75em;
	// 	}
	// }

	// .container{
	// 	// margin: 0px 0px !important;
	// 	 width: 70% !important;

	// }

	// div.iconTitle{
	// 	position: relative; 
	// 	top:95px;
	// 	height: 230px;
	// 	@media #{$small-only} { 
	// 		top:80px;
	// 	}
	// }
	// div.subcatTitle{
	// 	display: table;
	// 	height: 90px;
	// 			h1{
	// 				display: table-cell;
	// 				vertical-align: middle;
	// 			  }
	// 	@media #{$small-only} { 
	// 		height: 125px;
	// 		display: table;
	// 			h1{
	// 				display: table-cell;
	// 				vertical-align: middle;
	// 			  }
	// 	}
	// 	@media #{$medium-only} { 
	// 		height: 100px;
	// 		display: table;
	// 			h1{
	// 				display: table-cell;
	// 				vertical-align: middle;
	// 			  }
	// 	}
	// }
	// a.button{
	// 	width: 190px;
	// }

	// .circley{
	// 	border: 1px solid #fff;
	// }
 // }

// .subService-body{
// 	position: relative;
// 	margin-top: -61px;
// 	p a{
// 		color: $icandigit-blue;
// 	}

// 	p a.button{
// 		color: #fff;
// 	}



// 	h2{
// 		color: $icandigit-lt-brown;
// 	}

// 	h3{
// 		color: $icandigit-lt-brown;
// 	}

// 	section ul li{
// 		font-family: $raleway;
// 		list-style: none;
// 		color: $icandigit-blue;
// 		a{
// 		color: $icandigit-blue;	
// 		}
// 	}
	
// 	p{
// 		color: $icandigit-grey;
// 	}
// 	p.subService-description{

// 	font-size: 2rem;
// 		@media only screen and (max-width: 40em) { 
// 			font-size: 1.5rem;
// 		}
// 		color:#fff;
// 		@media #{$small-only} { 
// 		margin-bottom: 65px;
// 		}
// 	}

// }
body.gallery-archives{
	img.rsImg{
		margin-left:0px !important;
	}

	footer{
		margin-top: 20px;
	}
}

