
div.about{
	 background: 
	    linear-gradient(
	      rgba(82, 96, 110, 0.65),
	      rgba(82, 96, 110, 0.65)
	    ),
	url(../../images/about/the-learning-garden-preschool-about-header-kid-in-tunnel.jpg)
	no-repeat;

	background-size: cover; //100%
	// background-position: 0px -150px;
	
	@media #{$small-up} {
		background-position: 0px 0px;
	}

	@media #{$medium-up} {
		background-position: 0px -100px;
	}
	@media #{$large-up} {
		background-position: 0px -150px;
	}

	height: 340px;
	padding-top: 60px;
}

body.about div.about-body{
	// background-image: url(../../images/shared/clouds-sm.png);
	 background: 
	    linear-gradient(
	      rgba(255, 255, 255, 0.85),
	      rgba(255, 255, 255, 0.25)
	    );
	//margin-top: 60px;
	padding-top: 60px;
	padding-bottom: 30px;
	background-position-x: -1px;
	h4{
		// text-align: left !important;
		//color: #777;
		color: $learning-ltblue;
	}
	span.news-tag{
		padding-left: 20px;
		img{
			padding-left: 8px;
			height: 25px;
		}
	}
	span.post-date{
		color: $learning-ltgrey;
	}
	
	ul.staff{
		li{
			text-align: center;

			a img{
				border-radius: 100px;
				max-width: 200px;
				// display: block;
				width:100%;
				padding: 20px;
			}

			a{
				text-align: center;
				span.teacher-name{
				color: $learning-dkgrey;
				}
				span.bio-link{
				font-family: $raleway;
				text-transform: uppercase;
				font-size: .85em;
				}
			}
			
		}

	}
}

.bio-modal{
	img{
			border-radius: 100px;
			max-width: 200px;
			// display: block;
			width:100%;
			padding: 20px;
		}
	p{
		@extend .text-left;
	}

}

section#sidebar-about{

	a{
	//	float: left;
	}
	a span{
		color: $learning-dkgrey;
	}
	img{
		max-height: 50px;
		width: auto;
		margin-right: 10px;
		//float: left;

	}
	a.emailUs{
		@media #{$small-only} {
			font-size:.85em;
		}
	}
}

section.side-programs{
	table{
		border: 0px;
		background-color: transparent;
		tr, td{
			background-color: transparent;
		}
		td.iconner{
			text-align: center;
		}
	}

}

