
div.home{
	 background: 
	    linear-gradient(
	      rgba(82, 96, 110, 0.65),
	      rgba(82, 96, 110, 0.65)
	    ),
	url(../../images/home/the-learning-garden-preschool-header-kids-playing_xxsm.png)
	no-repeat;
	background-size: cover; //100%
	// background-position: 0px -50px;
	

	img#homeHeadLogo{
		width:100px;
	}
	height: 340px;
	padding-top: 60px;

	@media #{$small-up} {
		background-position: 0px 0px;
		height: 255px;
		padding-top: 20px;
		img#homeHeadLogo{
		width:50px;
		}
		
	}

	@media #{$medium-up} {
		//background-position: 0px -100px;
		height: 340px;
		padding-top: 60px;

		img#homeHeadLogo{
			width:100px;
		}
	}
	@media #{$large-up} {
		background-position: 0px 0px;
	}
	
	
	
}

a.homeHover{

	&:hover{
		@include single-transition(opacity, 300ms, ease-in-out);
		@include single-transition(color, 300ms, ease-in-out);
		opacity: .5;
		// border-bottom:1px solid #555;
	}
}

.program-wrapper{
	background-color: rgba(255,255,255,.75);
}


.homePrograms{
	// @extend .panel;
	// background-color: rgba(255,255,255,.25);
	padding-top: 40px;
	@media #{$small-up} {
		padding-top: 20px;	
		}
	h2{
		padding-bottom: 25px;

	}

	img{
		height: 79px;
		width: auto;
	}

	li{
		// @extend .panel;
	}
}

// #home-1{
// 	background: url(../../images/home/home-1-bg.jpg)
// 	// background: url(../../images/home/home-2-bg.jpg)
// 	// top / 100% /* position / size */
//     no-repeat                /* repeat */; 
h1{
	
	@media #{$small-only} { 
		font-size: 2em;
		//margin-top: 40px;
		
			margin-top: 10px;
		
		// margin-bottom: 60px;
	}
	color:#fff;
	text-align: center;
	span{
		font-weight: 900;
		text-transform: uppercase;
	}
	/* tag: */
	// line-height: 97px;
	text-shadow: 1px 1px 2px #030303;
	margin-top:  40px;
	//margin-bottom: 80px;
	
}

h2{
	color: rgba(3, 107, 153, 1);
}
h5{
	color: #fff;
	font-family: $merriweather;
}

div.program-copy{
	background-size: 100%;
	background-repeat: no-repeat;
	
	@extend .dkblue;
	font-style: italic;
		p.programSub{
		min-height: 50px;
		@media #{$small-only} { 
			min-height: 100px;
		}
	}
	div.ageNtime{
		p{
			font-style: normal;
			// color: $learning-purple;
		}
		p.age{
			margin-bottom: 0px;
		}

		span.time{
			border-top: #999 solid 1px;
		}
		// @extend .panel;
	}

}

ul.focus{
	
	li{
		
		text-align: left;
	}
}
	.upstairs{

		p.programSub{
			padding-top: 10px;
		}
		.ageNtime{
			color: $learning-purple;
		}
		// background-image: url(../../images/home/upstairs.JPG);
		// background-color: $learning-purple;
		
	}
	.downstairs{
	// 	background-image: url(../../images/home/downstairs.JPG);
		.ageNtime{
			color: $learning-green;
		}
	}
	.childcare{
	// 	background-image: url(../../images/home/childcare.JPG);
		.ageNtime{
			color: $learning-orange;
		}	
	}

body.home div.latest-news{
	//background-image: url(../../images/shared/clouds-sm.png);
	// background-color: rgba(190,221,234,1);
	padding-top: 30px;
	padding-bottom: 30px;
	background-position-x: -1px;

	h4{
		// text-align: left !important;
		//color: #777;
		color: $learning-ltblue;
	}
	span.news-tag{
		padding-left: 20px;
		img{
			padding-left: 8px;
			height: 25px;
		}
	}
	@media #{$small-only} {
		span.news-tag{
		padding-left: 8px;
		img{
			padding-left: 8px;
			height: 25px;

			}
		}
	}
	span.post-date{
		color: $learning-ltgrey;

	}
	img.newsImage{
		margin-bottom: 30px !important;
	}
}
div.newsImage{
		margin-bottom: 10px !important;
	}

// 	height: 100vh;
// 	margin-top: -45px;

// 	.row section#section-1{
// 		margin-top:10%;
// 	}
// }

// body.home{
//   .top-bar-section ul li.active a{
//   	border-bottom: 3px solid $icandigit-blue;
//   }
//   .top-bar-section ul li.active a span{
//     color: $icandigit-blue;	
  
//   }
// }


// .container {
//   width: 15%;
//   // height:300px;
//   @media #{$small-only} { 
// 	width: 30%;
// 	margin-top: 20px;
//   }
//   margin: 0 auto;
//   // background: blue;
// }

// .circle {
//   border-radius: 50%;
//   // width: 100%;
//   // padding-bottom: 100%;
//   background: white;
//   position: relative;
//   padding: 7px;

//   img{
//   	position: relative;
//   	// width:90%
//   }
// }

// .home-cta{
// 	a.button{
// 		width: 200px;
// 	}
// }


// #home-services{
// 	margin-top: 30px;
// 	margin-bottom: 20px;
// 	h2{
// 		font-size: 3em;
// 		font-weight: 400;
// 		color:#8B572A;
// 	}
// 	h3{
// 		//font-family: $merriweather;
// 		color: $icandigit-grey;
// 		font-size: 1.75em;

// 	}
// 	h3.sub{
// 		margin-top: 10px;
// 	}
// 	h4{
// 		text-transform: capitalize;
// 		font-size: 1.5rem;
// 	}
// 	h4.sub{
// 		color: $icandigit-grey;
// 		font-size: 1.25rem;	
// 		text-transform: none;
// 		font-family: $merriweather;
// 		a{
// 			color:$icandigit-blue;

// 			&:hover{
// 				text-decoration: underline;
// 			}
// 		}
// 	}

// 	ul.services{
// 		margin-top:50px;
// 		// li.circle{background: #000;}

// 		li{

// 			a.service-link{
// 				display: block;
// 				&:hover{
// 					@include single-transition(all, 200ms, ease-in-out);
// 					background: #e8e8e8;
					
// 					// h4, span.ss-icon{color: $icandigit-grey;
// 					// 	@include transition(all, 200ms, ease-in-out);}
			

// 					// div.circle{
// 					// 	background: none;
// 					// 	@include transition(all, 200ms, ease-in-out);
// 					//}
					

// 				}

// 			}

// 			.container{
// 				width: 50%;
// 			}
// 			ul li.service-arrow{
// 				margin-top: 10px;
// 			}

// 			span.ss-icon{
// 				font-size:1.25rem;
// 			}
// 		}

		
// 		li.trees{
// 			h4, span.ss-icon{color: $icandigit-green;}
			

// 			div.circle{
// 				background: $icandigit-green;
// 			}
			
// 		}

// 		li.excavation{
// 			h4, span.ss-icon{color: $icandigit-orange;}
// 			div.circle{
// 				background: $icandigit-orange;
// 			}
// 		}

// 		li.landscapes{
// 			h4, span.ss-icon{color: $icandigit-purple;}
// 			div.circle{
// 				background: $icandigit-purple;
// 			}
// 		}

// 		li.hardscapes{
// 			h4, span.ss-icon{color: $icandigit-red;}
// 			div.circle{
// 				background: $icandigit-red;
// 			}
// 		}		
// 	}
// 	ul.sub-services{
// 		margin-top: 20px;
// 		margin-left: 0px;
// 		li{
// 			text-transform: capitalize;
// 			list-style: none;
// 			color: $icandigit-grey;
// 			letter-spacing: 1.17px;
// 			line-height: 22px;
// 		}
// 	}
// }

// div#home-about{
// 	//background-color: rgba(151,151,151,.125);
// 	margin-bottom: -20px;
// 	padding: 40px 0px 20px 0px;

// 	p{
// 		color:$icandigit-grey;
// 	}
// }

// div#homeQuote-1, div#homeQuote-2{
// 	background-color: rgba(151,151,151,.125);
// 	color:$icandigit-grey;
// 	padding: 50px 0px 20px 20px;
// 	text-align: left;
// 	p{
// 		font-size: 1.5em;
// 		font-weight: bold;
// 		margin-left: 90px;
// 		position: relative;
// 		color:$icandigit-grey;

// 	}
// 	span.quote{
// 			float: left;
// 			position: relative;
// 			margin-left: 20px;
// 			top:-10px;
// 			font-size: 6em;
// 			line-height: 1em;
// 			opacity: .25;
// 			font-weight: 100;
			
// 		}
// }

// div#homeQuote-2{
// 	margin-bottom: 80px;
// }

// table#homeBene{
// 	border: none;
// 	a{
// 		color: $icandigit-blue;
// 	}
// 	tr{
// 		background: #fff;
// 	}
// 	td.labely{
// 		text-align: right;
// 		color: $icandigit-lt-brown;
// 		font-size: 1.5em;
// 		font-weight: 600;
// 		vertical-align: top;
//   		padding-top: 14px;
//   		font-family: $raleway;
// 	}
// }

// // scale
// // @mixin scale($scale) {
// // 	 @include transform(scale($scale));
// // } 
// // // translate
// // @mixin translate ($x, $y) {
// //    @include transform(translate($x, $y));
// // }

// // //ease in
// // @mixin translate ($x, $y) {
// //    @include transform(translate($x, $y));
// // }

// // @mixin animation-name($name...) {
// //   -o-animation-name: $name;
// //   -moz-animation-name: $name;
// //   -webkit-animation-name: $name;
// //   animation-name: $name;
// // }

// // @mixin keyframes($animation-name) {
// //   @-webkit-keyframes $animation-name {
// //     @content;
// //   }
// //   @-moz-keyframes $animation-name {
// //     @content;
// //   }
// //   @keyframes $animation-name {
// //     @content;
// //   }
// // }




// // @mixin transform($transforms) {
// // 	   -moz-transform: $transforms;
// // 	     -o-transform: $transforms;
// // 	    -ms-transform: $transforms;
// // 	-webkit-transform: $transforms;
// //           transform: $transforms;
// // }

// // @mixin animation($animation...) {
// //   -o-animation: $animation;
// //   -moz-animation: $animation;
// //   -webkit-animation: $animation;
// //   animation: $animation;
// // }



// // .bounce {
// //   // @include animation(bounce 2s infinite);
  
// // }