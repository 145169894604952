
div.programs{
	div.headLogo{
		border-radius: 50px;
	    margin: 0 auto;
	    width: 100px;
	    height: 100px;
	    box-shadow: 0px 0px 0px 1px #fff;
	    background-color: #f2f7f8;
	}
	
}


div.programs.preschool{
	 background: 
	    linear-gradient(
	      rgba(82, 96, 110, 0.65),
	      rgba(82, 96, 110, 0.65)
	    ),
	url(../../images/programs/the-learning-garden-preschool-program-students-smiling.jpg)
	no-repeat;
	background-size: cover; //100%
	//background-position: 0px -300px;
	@media #{$small-up} {
		background-position: -40px 0px;
	}

	@media #{$medium-up} {
		background-position: 0px -100px;
	}
	@media #{$large-up} {
		background-position: 0px -300px;
	}

	height: 340px;
	padding-top: 60px;
	
	div.headLogo{
    padding-top: 21px;
    border: 2px solid $learning-purple;
		img{
		}
	}

	h1{
		color: $learning-purple;
		margin-top: 15px;
	}

}

div.programs.pre-k{
	 background: 
	    linear-gradient(
	      rgba(82, 96, 110, 0.65),
	      rgba(82, 96, 110, 0.65)
	    ),
	url(../../images/programs/the-learning-garden-preschool-program-header-shoes-on-grass-sm.jpg)
	no-repeat;
	background-size: cover; //100%
	@media #{$medium-up} {
		background-position: 0px -100px;
	}
	@media #{$large-up} {
		background-position: 0px -300px;
	}
	height: 340px;
	padding-top: 60px;
	div.headLogo{
	    padding-top: 15px;
	    padding-right: 4px;
	    border: 2px solid $learning-green;
		img{
		}
	}

	h1{
		color: $learning-green;
		margin-top: 15px;
	}
}


div.programs.childcare{
	 background: 
	    linear-gradient(
	      rgba(82, 96, 110, 0.65),
	      rgba(82, 96, 110, 0.65)
	    ),
	url(../../images/programs/the-learning-garden-childcare-program-teacher-with-student.jpg)
	no-repeat;
	background-size: cover; //100%
	//background-position: 0px -420px;

	@media #{$small-up} {
		background-position: -40px 0px;
	}

	@media #{$medium-up} {
		background-position: 0px -100px;
	}
	@media #{$large-up} {
		background-position: 0px -320px;
	}

	height: 340px;
	padding-top: 60px;	

	div.headLogo{
    padding-top: 26px;
    border: 2px solid $learning-orange;
		img{
		}
	}

	h1{
		color: $learning-orange;
		margin-top: 15px;
	}

}

body.programs div.programs-body{
	// background-image: url(../../images/shared/clouds-sm.png);
	 background: 
	    linear-gradient(
	      rgba(255, 255, 255, 0.85),
	      rgba(255, 255, 255, 0.25)
	    );
	//margin-top: 60px;
	padding-top: 60px;
	padding-bottom: 30px;
	background-position-x: -1px;
	div.latest-news{
		margin-top: 20px;
	}
	h4{
		// text-align: left !important;
		//color: #777;
		color: $learning-ltblue;
	}
	span.news-tag{
		padding-left: 20px;
		img{
			padding-left: 8px;
			height: 25px;
		}
	}
	span.post-date{
		color: $learning-ltgrey;
	}

	ul.staff{
		li{
			text-align: center;

			a img{
				border-radius: 100px;
				max-width: 200px;
				// display: block;
				width:100%;
				padding: 20px;
			}

			a{
				text-align: center;
				span.teacher-name{
				color: $learning-dkgrey;
				}
				span.bio-link{
				font-family: $raleway;
				text-transform: uppercase;
				font-size: .85em;
				}
			}
			
		}

	}
}

.bio-modal{
	img{
			border-radius: 100px;
			max-width: 200px;
			// display: block;
			width:100%;
			padding: 20px;
		}
	p{
		@extend .text-left;
	}

}

section#sidebar-programs{

	a{
	//	float: left;
	}
	a span{
		color: $learning-dkgrey;
	}
	img{
		// height: 150px;
		width: auto;
		// margin-right: 10px;
		//float: left;
	}

	div.ageNtime{
		p{
			font-style: normal;
			// color: $learning-purple;
		}
		p.age{
			margin-bottom: 0px;
		}

		span.time{
			border-top: #999 solid 1px;
		}
		// @extend .panel;
	}

	ul.staff{
		li{
			text-align: center;
			text-align: center;

				span.teacher-name{
				color: $learning-dkgrey;
				}

				span.bio-link{
				font-family: $raleway;
				text-transform: uppercase;
				font-size: .85em;
				color: $learning-ltblue;
				}

				img{
				border-radius: 100px;
				max-width: 150px;
				// display: block;
				width:100%;
				padding: 20px;
				}

				img.user-icon{
					padding: 5px;
				    background-color: rgba(0,0,0,.15);
				    max-width: 110px;
				    width:100%;
				    margin-top: 20px;
				    margin-bottom: 20px;
				}
				
				div.ss-female-user{
				// background-image: url(../../images/shared/grey-rectangle.jpg);
				color: #fff;
			    border-radius: 100px;
			    max-width: 110px;
			    /* height: 100%; */
			    margin-top: 20px;
			    margin-left: auto;
			    margin-right: auto;
			    // padding: 20px;
			    background-color: rgba(0,0,0,0.15);
			    	img{
						position: relative;
				    	top: 10px;
				    }
				}
			
			a{

				text-align: center;

				span.teacher-name{
				color: $learning-dkgrey;
				}

				span.bio-link{
				font-family: $raleway;
				text-transform: uppercase;
				font-size: .85em;
				color: $learning-ltblue;
				}

				img{
				border-radius: 100px;
				max-width: 150px;
				// display: block;
				width:100%;
				padding: 20px;
				}

				img.user-icon{
					padding: 5px;
				    background-color: rgba(0,0,0,.15);
				    max-width: 110px;
				    width:100%;
				    margin-top: 20px;
				    margin-bottom: 20px;
				}
				
				div.ss-female-user{
				// background-image: url(../../images/shared/grey-rectangle.jpg);
				color: #fff;
			    border-radius: 100px;
			    max-width: 110px;
			    /* height: 100%; */
			    margin-top: 20px;
			    margin-left: auto;
			    margin-right: auto;
			    // padding: 20px;
			    background-color: rgba(0,0,0,0.15);
			    	img{
						position: relative;
				    	top: 10px;
				    }
				}
			}
			
		}

	}
}



