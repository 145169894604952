.royalSlider {
  width: 100%;
  height: 300px;
}

#gallery-1{
	// background-color: transparent !important;
	 //background-color: rgba(255,255,255,.75);
	 background-color: transparent;


	div.rsOverflow, div.rsContainer, div.rsSlide, div.rsNav, div.rsThumbs, div.rsThumbsHor{
		// background-color: transparent !important;
		//background-color: rgba(255,255,255,.5) !important;
		background-color: transparent;
	}
}

div.rsFullscreen{
	div.rsGCaption{
		top:-100px !important;
	}
	background-color: rgba(255,255,255,.9) !important;
}

.rsGCaption{
	display: none;
	// position: relative !important;
	// background-color: transparent !important;
	//background-color: #333 !important;
	//background-color: rgba(0,0,0,.75) !important;
	// top: 0px !important;
	// top: -96px !important;
	// font-family: 'Lato', sans-serif !important;
	// font-size: 18px !important;
	// font-style: normal;
	// font-weight: 300 !important;
	// text-align: center !important;
	// height: 50px !important;
	//color:$grey4 !important;
	// @media only screen and (max-width: 568px) {
	// 	display:none !important;
	// }

}

.rsThumbs{
	//margin-top: 25px;
}

.rsThumb.rsNavSelected{
	border: 2px solid #fff;
	background-color: transparent !important;
	img{
		opacity: 1 !important;
	}
}

.rsImg{
	//margin-top:3px !important;
}